/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, BoxProps, chakra, useToken } from '@chakra-ui/react';

interface WavesProps extends BoxProps {
  color: string;
}

const Waves: FC<WavesProps> = React.memo(({ color, ...props }) => {
  const [baseColor] = useToken('colors', [color]);
  return (
    <Box position='absolute' bottom={0} left={0} width='100%' overflow='hidden' lineHeight={0} {...props}>
      <chakra.svg
        position='relative'
        display='block'
        width='calc(100% + 1.3px)'
        height='250px'
        viewBox='0 0 1440 400'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
        className='transition duration-300 ease-in-out delay-150'
      >
        <path
          d='M 0,400 C 0,400 0,80 0,80 C 97.42857142857142,87.82142857142857 194.85714285714283,95.64285714285714 327,87 C 459.14285714285717,78.35714285714286 626,53.24999999999999 761,56 C 896,58.75000000000001 999.1428571428571,89.35714285714286 1107,98 C 1214.857142857143,106.64285714285714 1327.4285714285716,93.32142857142857 1440,80 C 1440,80 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill={`${baseColor}44`}
          className='transition-all duration-300 ease-in-out delay-150 path-0'
        />
        <path
          d='M 0,400 C 0,400 0,160 0,160 C 118.39285714285714,178.67857142857144 236.78571428571428,197.35714285714286 367,188 C 497.2142857142857,178.64285714285714 639.2500000000001,141.25 774,135 C 908.7499999999999,128.75 1036.2142857142858,153.64285714285717 1146,163 C 1255.7857142857142,172.35714285714283 1347.892857142857,166.17857142857142 1440,160 C 1440,160 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill={`${baseColor}66`}
          className='transition-all duration-300 ease-in-out delay-150 path-1'
        />
        <path
          d='M 0,400 C 0,400 0,240 0,240 C 142.53571428571428,231.96428571428572 285.07142857142856,223.92857142857142 387,232 C 488.92857142857144,240.07142857142858 550.2500000000001,264.24999999999994 659,259 C 767.7499999999999,253.75000000000006 923.9285714285713,219.0714285714286 1062,211 C 1200.0714285714287,202.9285714285714 1320.0357142857142,221.4642857142857 1440,240 C 1440,240 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill={`${baseColor}88`}
          className='transition-all duration-300 ease-in-out delay-150 path-2'
        />
        <path
          d='M 0,400 C 0,400 0,320 0,320 C 107.35714285714286,338.4642857142857 214.71428571428572,356.92857142857144 354,346 C 493.2857142857143,335.07142857142856 664.5,294.74999999999994 773,290 C 881.5,285.25000000000006 927.2857142857142,316.0714285714286 1028,327 C 1128.7142857142858,337.9285714285714 1284.357142857143,328.96428571428567 1440,320 C 1440,320 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill={`${baseColor}ff`}
          className='transition-all duration-300 ease-in-out delay-150 path-3'
        />
      </chakra.svg>
    </Box>
  );
});

Waves.displayName = 'Waves';
export default Waves;
